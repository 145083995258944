import { useFlags, useFlagsmith } from 'flagsmith/react';
import { IFlagsmithFeature } from 'flagsmith/types';
import { useEffect, useState } from 'react';
import { TupleToUnion } from 'type-fest';
import { useFlagsmithIdentityLoading } from '../providers/FeatureFlagProvider';

const useForceLoad = (): boolean => {
  const [forceLoad, setForceLoad] = useState<boolean>(false);
  const threeSeconds = 3000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceLoad(true);
    }, threeSeconds);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  return forceLoad;
};
export const SUPPORTED_FEATURE_FLAG_KEYS = [
  'flag_app_redesign_2024',
  'flag_adjustable_hero_height',
  'flag_ai_metadata_suggestions',
  'flag_anonymous_edit',
  'flag_ask_lenny',
  'flag_aurora_embed_codes',
  'flag_brandfetch_wtw',
  'flag_caption_customizations',
  'flag_channel_customize_updates',
  'flag_channel_episode_list_metrics',
  'flag_customize_thumbnails_v2',
  'flag_customize_v4',
  'flag_embed_options_timeline_items',
  'flag_enable_editor_audio',
  'flag_extended_audio_descriptions',
  'flag_fast_uploads',
  'flag_folder_page_info_panel',
  'flag_hackathon_compare_media',
  'flag_hackathon_relative_compare_chart',
  'flag_integrations_funnel_v2',
  'flag_ai_highlights_v2',
  'flag_lead_gen_v1',
  'flag_live_connection_indicator',
  'flag_live_event_account_overview',
  'flag_live_event_permissions',
  'flag_live_layout_redesign',
  'flag_live_layout_redesign_expanded',
  'flag_live_linkedin_live',
  'flag_live_native_slideshow',
  'flag_live_on_safari',
  'flag_live_simulive',
  'flag_live_stage_informer',
  'flag_live_tracks',
  'flag_live_update_default_thumbnail_emails',
  'flag_live_webcam_backgrounds',
  'flag_live_wistia_player_conversion',
  'flag_media_pricing_experiment_refactor',
  'flag_mobile_funnel_v1',
  'flag_my_state_of_video',
  'flag_new_account_home',
  'flag_new_user_role',
  'flag_onboarding_embed_share_v1',
  'flag_on_demand_lead_sync',
  'flag_private_user_sessions_v2',
  'flag_recent_searches',
  'flag_record_group_recording',
  'flag_remotion_preview',
  'flag_revamped_search',
  'flag_seamless_git',
  'flag_search',
  'flag_share_for_review',
  'flag_show_conversion_analytics',
  'flag_show_traffic_analytics',
  'flag_show_transcript_editor_hover_menu',
  'flag_transcript_translations',
  'flag_upcoming_invoice_account_settings',
  'flag_updated_folder_permissions',
  'flag_use_onboarding',
  'flag_vma_brand_refresh',
  'flag_workspaces',
  'flag_wtw_add_video_experiment',
  'flag_wtw_new_question',
  'flag_live_audio_video_ux_updates',
  'flag_channel_playlist',
] as const;

export type SupportedFeatureFlags = TupleToUnion<typeof SUPPORTED_FEATURE_FLAG_KEYS>;

export type UseLoadFeatureFlagsReturn<
  Flag extends SupportedFeatureFlags = Partial<SupportedFeatureFlags>,
> = {
  readonly loading: boolean;

  readonly flags: { [Key in Flag]: IFlagsmithFeature };
};

/**
 * V2 hook differs from v1 by checking if a flag has been loaded. Flags that are not loaded are returned as disabled by flagsmith by default.
 * Testing example can be seen here webpack/singlePageApp/hooks/useLoadFeatureFlagsV2.test.jsx
 */
export const useLoadFeatureFlags = <
  Flag extends SupportedFeatureFlags = Partial<SupportedFeatureFlags>,
  Trait extends string = '',
>(
  flags: Flag[],
  traits: Trait[] | undefined = undefined,
): UseLoadFeatureFlagsReturn<Flag> => {
  /**
   * If a user has never had the flag explicitly toggled on/off
   * OR flagsmith goes down, we need to force the loading state
   * after a few seconds to avoid the UI getting stuck in an infinite
   * loader.
   */
  const forceLoad = useForceLoad();
  const flagsmith = useFlagsmith();
  const result = useFlags(flags, traits);
  const flagsmithStateFlags = flagsmith.getState().flags;
  const flagsmithIdentityLoading = useFlagsmithIdentityLoading();

  const hasAllFlags =
    forceLoad ||
    flags.reduce((acc, flag) => {
      if (flagsmithStateFlags === undefined) {
        return false;
      }
      /**
       * Disabling because the TS compiler doesn't realize that we can provide a string that's not in the flags object.
       * Possible typing problem in Flagsmith
       */
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return acc && !!flagsmithStateFlags[flag];
    }, true);

  return {
    loading: flagsmithIdentityLoading || !hasAllFlags,
    flags: result,
  };
};
